var $window, $document, $body, globalXHRStatus, yModalConfig;

var conf = {
    'fancyStatus': false,
    'nodeBody': $('#body'),
    'fancyDefault': {
        keyboard: false,
        smallBtn: false,
        toolbar: false,
        animationEffect: 'fade',
        backFocus: false,
        touch: {
            vertical: false, // Allow to drag content vertically
            momentum: false // Continue movement after releasing mouse/touch when panning
        },
        afterShow: function () {
            conf.nodeBody.addClass('body-state-fancy');
        },
        beforeClose: function () {
            conf.nodeBody.removeClass('body-state-fancy');
        },
        afterClose: function () {
            conf.fancyStatus = false;
        }
    }
};

var moduleApp = {
    'init': function () {
        this.initArticlesPagination();
        this.initGlobals();
        this.initPreloader();
        this.initGlobalsEvents();
        this.executeModules();
        this.loaderImages();
        this.itemsHoverCalc();
        this.toTop();
    },
    'getRandom': function (min, max) {
        return Math.round(min - 0.5 + Math.random() * (max - min + 1));
    },
    'initArticlesPagination' : function(){
        if($('.js-articles-show-more').length){
            var $pagination = $('.js-articles-show-more');
            $pagination.on('click', function (e) {
                var paginationSize = parseInt($(this).closest('.js-articles-pagination').data('pagination-size'), 10);
                var tagHandler = $('.js-articles-size-select');
                var tagsLink = tagHandler.data('tags-link');
                var pageNav = $(this).data('nav');
                var curPage = parseInt($(this).data('page'), 10);
                var baseParams = {
                    pageSize: paginationSize,
                    tags: tagsLink,
                    isAjax: 'Y',
                };
                curPage += 1;
                baseParams[pageNav] = curPage;
                axios.get(
                    '/blog/',
                    {
                        params:baseParams,
                    }
                ).then(function(resp){
                    var $articlesBody = $('.js-articles-list-body');
                    var $resultBody = $(resp.data.articles_body);
                    var $articlesPagination = $('.js-articles-pagination');
                    var $resultPagination = $(resp.data.articles_pagination);
                    $articlesBody.append($resultBody);
                    $articlesPagination.html($resultPagination);
                    moduleApp.loaderImages($resultBody.find('[data-img]'));
                    moduleApp.initArticlesPagination();
                })
            });
        }
        if($('.js-articles-size-select').length){
            var $pageSizeSelector = $('.js-articles-size-select');
            $pageSizeSelector.on('change', function () {
                var $currentOption = $(this).find('option:selected');
                var tagsLink = $(this).data('tags-link');
                if(tagsLink) {
                    $(location).attr('href', '/blog/?tags=' + tagsLink + '&show=' + parseInt($currentOption.val()), 10);
                } else {
                    $(location).attr('href', '/blog/?show=' + parseInt($currentOption.val()), 10);
                }
            });
        }
    },
    'initGlobals': function () {
        $window = $(window);
        $document = $(document);
        $body = $('#body');
        globalXHRStatus = false;
        $window.on('load', function () {
            $window.trigger('resize');
        });
        $('[data-fancybox]').fancybox({
            backFocus: false,
        });
    },
    'initPreloader': function () {
        Pace.on('done', function () {
            $('.preloader').addClass('hidden');

            setTimeout(function () {
                $('.preloader').remove();
            }, 500);
        });
    },
    'initGlobalsEvents': function () {

        var methods = {
            init: function () {
                methods.getInitialEvents('click');
                methods.getInitialEvents('change');
            },
            getInitialEvents: function (thisEvent) {
                $document.on(thisEvent, '[data-g' + thisEvent + ']', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var thisAction = $this.attr('data-g' + thisEvent);
                    var thisValue = $this.val() || $this.data().value;
                    if (actions[thisAction]) {
                        actions[thisAction]($this, thisValue);
                    } else {
                    }
                });
            },
            getTarget: function (thisTarget) {
                return $('[data-target="' + thisTarget + '"]');
            },
        };

        var actions = {

            'm-menu': function () {
                var $menuBtn = $('.js-hamburger');
                var $menu = $('.js-is-mobile');
                $menuBtn.toggleClass('is-active');
                $menu.toggleClass('is-active');
            },
            'showModal': function ($this, thisValue) {
                $.fancybox.close();

                var $modalParent = $('#' + thisValue.template);

                if ($modalParent.length < 1) {
                    return false;
                }

                var thisFancyConfig = $.extend({}, conf.fancyDefault, {
                    src: '#' + thisValue.template
                });

                $.fancybox.open(thisFancyConfig);
            },
            'closeFancy': function () {
                $.fancybox.close();
            },
            'share-page': function ($this, thisValue) {
                var shareService = thisValue;
                var shareData = {
                    'link': $('[property="og:url"]').attr('content'),
                    'title': encodeURI($('[property="og:title"]').attr('content')),
                    'description': encodeURI($('[property="og:description"]').attr('content')),
                    'image': $('[property="og:image"]').attr('content')
                };

                var windowLink = 'http://share.yandex.ru/go.xml?service=' + shareService;
                var fbWindowLink = 'http://www.facebook.com/sharer/sharer.php?';
                windowLink += '&title=' + shareData.title;

                if (shareService == 'twitter') {
                    windowLink += ' ' + shareData.description;
                    windowLink += '&url=' + shareData.link;
                    windowLink += '&link=' + shareData.link;
                } else if (shareService == 'facebook') {
                    fbWindowLink += 'u=' + shareData.link;
                } else {
                    windowLink += '&url=' + shareData.link;
                    windowLink += '&link=' + shareData.link;
                    windowLink += '&description=' + shareData.description;
                    windowLink += '&image=' + shareData.image;
                }
                if (shareService == 'facebook') {
                    window.open(fbWindowLink, '', 'toolbar=0,status=0,width=625,height=435');
                } else {
                    window.open(windowLink, '', 'toolbar=0,status=0,width=625,height=435');
                }
            },
            'print-page': function () {
                window.print();
            },
            'scroll-to-anchor': function ($this) {
                var target = $this.attr('href');
                if (target.length) {
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top - 70
                    }, 700);
                }
            },
            'scroll-top': function () {
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            },
            'filter': function ($this) {
                $this.closest('form').submit();
            },
            'toggleFooterMenu': function ($this) {
                $this.closest('.col').toggleClass('is-show-meta-b');
            },
            'toggleProductVariants': function ($this) {
                $this.closest('.product-snippet').toggleClass('is-show-variants');
            },
            'toggleCatNavDropdown': function ($this) {
                $this.closest('.page-index__cat-nav__menu').toggleClass('is-show-dropdown');
            },
            'toggleNavDropdown': function ($this) {
                $this.closest('.page-nav__menu').toggleClass('is-show-dropdown');
            },
            'togglePersonalNavDropdown': function ($this) {
                $this.closest('.page-personal__nav__menu').toggleClass('is-show-dropdown');
            },
            'showMobileMenu': function ($this) {
                $body.addClass('is-show-mobile-header');
            },
            'hideMobileMenu': function ($this) {
                $body.removeClass('is-show-mobile-header');
            },
            'openFilter': function ($this) {
                $body.addClass('is-show-filter');
            },
            'closeFilter': function ($this) {
                $body.removeClass('is-show-filter');
            },
            'showHeaderSearch': function ($this) {
                $this.closest('.is-header').addClass('is-show-search');
            },
            'hideHeaderSearch': function ($this) {
                $this.closest('.is-header').removeClass('is-show-search');
                $('.is-header__search').removeClass('is-show-result');
            },
        };

        methods.init();
    },
    'itemsHoverCalc': function () {
        var $gridCell = $('.is-grid__item:not(.inited)');

        $gridCell.each(function (idx, el) {
            var h = $(el).outerHeight();
            $(el).find('.product-snippet__hover').css({ "padding-top": (h + 8) + "px" });
            $gridCell.addClass('inited');
        });

        $window.on('resize', function () {
            setTimeout(function () {
                $gridCell.each(function (idx, el) {
                    var h = $(el).outerHeight();
                    $(el).find('.product-snippet__hover').css({ "padding-top": (h + 8) + "px" });
                });
            }, 100);
        })
    },
    'executeModules': function () {
        $('[data-is]').each(function (i, thisModule) {
            var $thisModule = $(thisModule);
            var thisModuleName = $thisModule.attr('data-is');
            if (moduleApp[thisModuleName]) {
                moduleApp[thisModuleName]($thisModule);
            }
        });
    },
    'header': function ($header) {
        var fixed = false;

        $window.on('scroll', function () {
            if ($window.scrollTop() > $header.outerHeight() && !fixed) {
                $header.css({
                    height: $header.outerHeight(),
                });
                $header.addClass('is-fixed');
                fixed = true;
            } else if ($window.scrollTop() <= $header.outerHeight() && fixed) {
                $header.css({
                    height: 'auto',
                });
                $header.removeClass('is-fixed');
                fixed = false;
            }
        });
    },
    'header-search': function ($thisModule) {
        var timeout;

        var $field = $thisModule.find('.is-header__search__field');

        var $result = $thisModule.find('.is-header__search__result');

        var send = function () {
            $.ajax({
                type: 'get',
                url: $thisModule.data('ajax'),
                data: {
                    q: $field.val().trim(),
                },
                dataType: 'json',
                success: function (result) {
                    $result.html(result.html);
                    $thisModule.addClass('is-show-result');
                    moduleApp.loaderImages();
                    setTimeout(function () {
                        moduleApp.itemsHoverCalc();
                    }, 250);
                },
            });
        };

        $field.on('keyup', function () {
            clearTimeout(timeout);
            if ($field.val().trim().length > 1) {
                timeout = setTimeout(send, 500);
            } else {
                $thisModule.removeClass('is-show-result');
            }
        });

        $document.on('click', function (e) {
            var $target = $(e.target);

            if (!$target.closest('.is-header__search').length && !$target.closest('[data-gclick="showHeaderSearch"]').length) {
                $('.is-header').removeClass('is-show-search');
                $('.is-header__search').removeClass('is-show-result');
            }
        });
    },
    'aside-fixed': function ($aside) {
        new Sticky('.side-bar-layout__aside .block-decor-line2');
        new Sticky('.side-bar-layout__aside .block-decor-line');
    },
    'tags': function ($tags) {
        var nav = priorityNav.init({
            mainNavWrapper: '.tags-list__tags',
            breakPoint: 0,
            throttleDelay: '50',
            navDropdownLabel: 'Еще',
            navDropdownLabelBack: 'Свернуть',
        });
    },
    'cookie': function ($cookie) {
        if (localStorage.cookieAgree !== 'yes') {
            $cookie.show();
        }
        $cookie.find('.cookie__btns__agree').on('click', function (e) {
            e.preventDefault();
            localStorage.cookieAgree = 'yes';
            $cookie.hide();
        });
    },
    'inViewLoader': function () {
        var hiddenClass = 'view-hidden';
        var $inView = $('.' + hiddenClass);

        $inView.each(function (i, e) {
            var $e = $(e);
            var r1 = parseInt(Math.random() * 1000) + 500;
            var r2 = parseInt(Math.random() * 200);
            var string = 'transition-duration:' + r1 + 'ms;transition-delay:' + r2 + 'ms;transition-property:opacity;';
            var style = $e.attr('style');
            if (style) {
                string = string + style;
            }
            $e.attr('style', string);

        });

        $inView.on('inview', function (event, isInView) {
            if (isInView) {
                $(this).removeClass(hiddenClass);
            }
        });
    },
    'loaderImages': function ($img) {

        var inviewFlag = true;
        if (!$img) {
            $img = $('[data-img]');
        }

        $img.each(function (i, thisImg) {

            var $thisImage = $(thisImg);
            var $parentNode = $thisImage.parent();

            var imgUrl = $thisImage.data().img || '';
            var imgMiddle = $thisImage.data().middle || false;
            var imgVisible = $thisImage.data().visible || false;
            var nativeStyle = $thisImage.attr('style') || '';

            if (!imgVisible) {
                $thisImage.addClass('sfx-hidden');
            }

            $thisImage.removeAttr('data-img');
            var tempImage = new Image();
            tempImage.src = imgUrl;
            tempImage.onload = function () {


                var r1 = moduleApp.getRandom(500, 1200);
                var r2 = moduleApp.getRandom(0, 300);

                if ($thisImage.is('img')) {
                    $thisImage.attr('src', imgUrl);


                    if (imgMiddle) {
                        var middleArray = {};
                        middleArray.width = parseInt($thisImage.width());
                        middleArray.height = parseInt($thisImage.height());
                        middleArray.halfTop = parseInt(middleArray.height / 2);
                        middleArray.halfLeft = parseInt(middleArray.width / 2);
                        nativeStyle += 'position:absolute;display:block;top:50%;left:50%;width:' + middleArray.width + 'px;height:' + middleArray.height + 'px;margin:-' + middleArray.halfTop + 'px 0 0 -' + middleArray.halfLeft + 'px;';
                    }

                    $thisImage.attr('style', nativeStyle + 'transition-property:opacity;transition-duration:' + r1 + 'ms;transition-delay:' + r2 + 'ms;');
                } else {
                    $thisImage.attr('style', nativeStyle + 'background-image:url(' + imgUrl + ');transition-property:opacity;transition-duration:' + r1 + 'ms;transition-delay:' + r2 + 'ms;');
                }
                if ($parentNode.hasClass('js-zoom-image')) $parentNode.zoom();
            };
        });

        if (inviewFlag) {
            $img.on('inview', function (event, isInView, howView) {
                if (isInView) {
                    var $this = $(this);
                    $(this).removeClass('sfx-hidden');
                }
            });
        } else {
            $img.removeClass('sfx-hidden');
        }
    },
    'tabs-nav': function ($thisModule, inputConfig) {
        var defaultConfig = {
            'speed': '300',
            'initAttr': 'data-tab-body',
            'initAttrType': '.',
            'onBeforeChange': false,
            'onAfterChange': false,

            // private config

            'opacityDuration': '150',
            'opacityDelay': '150',
            'wrapperClass': '.is-tabs__wrapper',
            'contentClass': '.is-tabs__tab',
            'navLinkClass': '> a, > .is-tabs__link'
        };

        var tabsConfig = $.extend({}, defaultConfig, inputConfig);

        var debouncer = false;
        var playStringRaw = 'transition:height ' + tabsConfig.speed + 'ms 0s, opacity ' + (parseInt(tabsConfig.speed) + parseInt(tabsConfig.opacityDuration)) + 'ms ' + tabsConfig.opacityDuration + 'ms;';
        var playStringCSS = '-webkit-' + playStringRaw + '-moz-' + playStringRaw + '-ms-' + playStringRaw + '-o-' + playStringRaw + playStringRaw;
        var containerBox = $thisModule.attr(tabsConfig.initAttr) || false;

        var $navLinks = $thisModule.find(tabsConfig.navLinkClass);
        var $containerBox = $(tabsConfig.initAttrType + containerBox);
        var $wrapperBox = $containerBox.find(tabsConfig.wrapperClass);
        var $contentBox = $containerBox.find(tabsConfig.contentClass);

        var cH = $wrapperBox.outerHeight();
        var lastIndex = -1;

        var tabsCore = {
            'addEvent': function () {
                $navLinks.on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    if (debouncer || $this.hasClass('active')) { return false; }
                    debouncer = true;
                    var thisIndex = $this.index();
                    $navLinks.removeClass('active');
                    $this.addClass('active');
                    if (tabsConfig.onBeforeChange) {
                        var cbReturn = tabsConfig.onBeforeChange({
                            'oldIndex': lastIndex,
                            'newIndex': thisIndex,
                            'thisLink': $this,
                            'thisContent': $contentBox.eq(thisIndex)
                        });
                        if (cbReturn === false) {
                            debouncer = false;
                            return false;
                        }
                    }
                    $containerBox.attr('style', 'opacity:0;height:' + cH + 'px;');
                    tabsCore.changeTab(thisIndex);
                });
            },
            'changeTab': function (thisIndex) {
                $contentBox.hide().eq(thisIndex).show();
                cH = $wrapperBox.outerHeight();
                $containerBox.attr('style', 'opacity:1;height:' + cH + 'px;' + playStringCSS);
                setTimeout(function () {
                    $containerBox.attr('style', 'height:auto;');
                    if (tabsConfig.onAfterChange) {
                        var cbReturn = tabsConfig.onAfterChange({
                            'oldIndex': lastIndex,
                            'newIndex': thisIndex,
                            'thisLink': $navLinks.eq(thisIndex),
                            'thisContent': $contentBox.eq(thisIndex)
                        });
                        if (cbReturn === false) {
                            debouncer = false;
                            return false;
                        }
                    }
                    lastIndex = thisIndex;
                    debouncer = false;
                    conf.nodeWindow.trigger('resize');

                }, parseInt(tabsConfig.speed) + 25);
            }
        };

        // init
        tabsCore.addEvent();
        $navLinks.eq(0).addClass('active');
    },
    'accordion': function ($thisModule) {

        if ($thisModule[0].hasAttribute('mobile-false') && appConfig.mobileVersion) {
            $thisModule.find('.ia-item').addClass('active');
            $thisModule.find('.ia-title-link').hide();
            return false;
        }
        $thisModule.find('.js-acc-open').click(function (e) {
            e.preventDefault();
            var $this = $(this);
            var $currentAccItems = $this.closest('.is-accordion').find('.ia-item');
            var $currentAccWrapper = $this.closest('.is-accordion').find('.ia-content-wrapper');

            var $currentItem = $this.closest('.ia-item');
            var $currentWrapper = $currentItem.find('.ia-content-wrapper');
            var cH = $currentItem.find('.ia-content').height();

            if ($currentItem.hasClass('active')) {
                if (!$this.hasClass('state-unclose')) {
                    $currentItem.removeClass('active');
                    $currentWrapper.removeAttr('style');
                    setTimeout(function () { $window.trigger('resize'); }, 350);
                }
            } else {
                $currentAccItems.removeClass('active');
                $currentItem.addClass('active');
                $currentAccWrapper.removeAttr('style');
                $currentWrapper.attr('style', 'height:' + cH + 'px;');
                setTimeout(function () { $window.trigger('resize'); }, 350);
            }
            var altText = $currentItem.find('.ia-title-link').data('alt-text');
            var defaultText = $currentItem.find('.ia-title-link').data('default-text');
            if (typeof altText !== 'undefined' && typeof defaultText !== 'undefined' && altText !== '' && defaultText !== '') {
                if ($currentItem.hasClass('active')) {
                    $currentItem.find('.ia-title-link').text(altText)
                } else {
                    $currentItem.find('.ia-title-link').text(defaultText)
                }
            }
        });
    },
    'formFocused': function () {
        var $inputs = $('input, textarea');

        $inputs.on('change', function () {
            var $this = $(this);
            $this.val().length > 0 ? $this.closest('.is-form__field').addClass('state-focused') : $this.closest('.is-form__field').removeClass('state-focused');
        });

        $inputs.each(function (i, e) {
            var $this = $(e);
            $this.val().length > 0 ? $this.closest('.is-form__field').addClass('state-focused') : $this.closest('.is-form__field').removeClass('state-focused');
        });
    },
    'form-validation': function ($thisModule, thisCallback) {

        thisCallback = thisCallback || false;

        var params = {
            'fields': {
                'attr': 'validation',
                'parentClass': 'is-form-field',
                'parentErrorState': 'state-error',
                'errorNode': 'ff-error-parent',
                'formFocusClass': 'state-focus',
                'formHasValueClass': 'state-has-value',
                'fromInitedClass': 'state-inited',
                'fieldInitidClass': '_field-inited',
                'initialNodes': $(null),
                'passwordFields': {
                    'primaryNode': $(null),
                    'secondaryNode': $(null)
                },
                'maskedBlank': '–'
            },
            'form': {
                'moduleParent': $thisModule,
                'parent': $thisModule.find('form'),
                'submitLinkClass': 'js-form-submit',
                'validFormClass': 'form-state-valid',
                'invalidFormClass': 'form-state-invalid',
                'progressFormClass': 'form-state-progress',
                'progressButtonClass': 'button-state-progress'
            },
            'inner': {
                'submitCallback': thisCallback,
                'realTimeCheck': false,
                'defaultFieldData': {
                    'mask': 'none',
                    'require': true,
                    'visible': true,
                    'error': '',
                    'cleaveMask': false
                }
            }
        };

        var methods = {
            'init': function () {
                methods.initFields();
                methods.initEvents();
                methods.initFieldsMarkup();
                methods.initFormStatus();
            },
            // 'grecaptchaInit': function () {
            //   grecaptcha.ready(function () {
            //     grecaptcha.execute('6LfDsKUUAAAAAHu8Afl2Dowg7BY2gmhMne382bBD', {
            //       action: 'create_comment'
            //     }).then(function (token) {
            //       params.form.parent.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            //     });
            //   });
            // },
            'initFields': function () {
                params.fields.initialNodes = params.form.parent.find('[data-' + params.fields.attr + ']');
            },
            'initEvents': function () {
                params.form.parent.on('submit', methods.eventFormSubmit);
                params.form.parent.find('.' + params.form.submitLinkClass).on('click', methods.eventSubmitLinkClick);
                params.form.parent.find('.button--review').on('click', methods.validateForm);
                $thisModule.on('keypress input change', '[data-' + params.fields.attr + ']', methods.eventChangeFields);
                $thisModule.on('keydown', '[data-' + params.fields.attr + ']', methods.eventFormSubmitEnter);
                $thisModule.on('focus', '[data-' + params.fields.attr + ']', methods.eventFocusEnter);
                $thisModule.on('blur', '[data-' + params.fields.attr + ']', methods.eventFocusLeave);
            },
            'initFieldsMarkup': function () {
                params.form.parent.find('[data-' + params.fields.attr + ']:not(.' + params.fields.fieldInitidClass + ')').each(function (i, thisField) {
                    var $thisField = $(thisField);
                    var thisFieldDataRaw = $thisField.data()[params.fields.attr];
                    var thisFieldData = $.extend({}, params.inner.defaultFieldData, thisFieldDataRaw);

                    // error message
                    if (thisFieldData.error) {
                        $thisField.closest('.' + params.fields.parentClass).append('<div class="' + params.fields.errorNode + '">' + thisFieldData.error + '</div>');
                    }

                    // password fields
                    if (thisFieldData.mask == "passwordPrimary") {
                        params.fields.passwordFields.primaryNode = $thisField;
                    }
                    if (thisFieldData.mask == "passwordSecondary") {
                        params.fields.passwordFields.secondaryNode = $thisField;
                    }

                    // masked input
                    if (thisFieldData.cleaveMask) {
                        if (thisFieldData.mask == 'phone') {
                            $thisField.inputmask({
                                mask: "+7 (999) 999 99 99",
                                placeholder: "–",
                                showMaskOnHover: false,
                                clearIncomplete: true,
                                positionCaretOnClick: "radixFocus"
                            })
                        } else {
                            try {
                                new Cleave($thisField[0], thisFieldData.cleaveMask);
                            } catch (e) {
                                console.log(e)
                            }
                        }
                    }

                    $thisField.addClass(params.fields.fieldInitidClass);
                    $thisField.closest('.' + params.fields.parentClass).addClass(params.fields.fromInitedClass);
                });
            },
            'initFormStatus': function () {
                if (methods.checkingFieldsAll(params.fields.initialNodes, true)) {
                    params.form.moduleParent.addClass(params.form.validFormClass);
                    params.form.moduleParent.removeClass(params.form.invalidFormClass);
                } else {
                    params.form.moduleParent.removeClass(params.form.validFormClass);
                    params.form.moduleParent.addClass(params.form.invalidFormClass);
                }
            },
            'eventFormSubmitEnter': function (e) {
                if (e.keyCode == 13) {
                    methods.eventFormSubmit();
                    return false;
                }
                return true;
            },
            'eventFormSubmit': function () {
                methods.initFields();
                methods.initFieldsMarkup();
                methods.initFormStatus();

                // if form in progress
                if (params.form.moduleParent.hasClass(params.form.progressFormClass)) {
                    return false;
                }

                // enable real time checking
                params.inner.realTimeCheck = true;

                // return state - is a error state
                // if `FALSE` - fields has error and submit is prevent
                // if `TRUE` - no error and form going submit
                var returnState = methods.checkingFieldsAll(params.fields.initialNodes);

                // if fields valid then add progress state to form

                if (returnState == true) {
                    params.form.moduleParent.addClass(params.form.progressFormClass);
                    $('.' + params.form.submitLinkClass).addClass(params.form.progressButtonClass);
                }

                // if submit callback
                if (returnState && params.inner.submitCallback) {
                    params.inner.submitCallback();

                    // grecaptcha.ready(function() {
                    //   grecaptcha.execute('6LfDsKUUAAAAAHu8Afl2Dowg7BY2gmhMne382bBD', {action: 'create_comment'}).then(function(token) {
                    //     params.form.parent.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                    //     params.inner.submitCallback();
                    //   });
                    // });

                    return false;
                }

                return returnState;
            },
            'eventSubmitLinkClick': function (e) {
                e.preventDefault();
                params.inner.submitCallback = function () {
                    var form = params.form.parent[0];

                    if(form.id == 'fb-forgotpasswd') {
                        var loginField = form.find('[name="USER_LOGIN"]');
                        var emailField = form.find('[name="USER_EMAIL"]');
                        if (loginField != undefined) {
                            loginField.val(emailField.val());
                        }
                    }
                    
                    var formData = new FormData(params.form.parent[0]);

                    $.ajax({
                        url: params.form.parent.attr('action'),
                        method: 'post',
                        data: formData,
                        cache: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            var res = $.parseJSON(data);

                            if (res.auth) {
                                analytics.login();
                                window.location.replace(res.url);
                                return;
                            }
                            if (res.registration) {
                                analytics.registration();
                            }
                            if (res.callback) {
                                analytics.callback();
                            }
                            if (res.review) {
                                analytics.review();
                            }

                            var title = res.title ? res.title : '';
                            var t = '<div class="is-popup is-style">';
                            t += '<div class="is-popup__inner">';
                            t += '<h4 class="is-popup__title">' + title + '</h4>';
                            t += '<p class="is-popup__text">' + res.msg + '</p>';
                            t += '</div>';
                            t += '</div>';

                            params.form.parent[0].reset();
                            $.fancybox.close();

                            $.fancybox.open(t);
                            params.form.moduleParent.removeClass(params.form.progressFormClass);
                            $('.' + params.form.submitLinkClass).removeClass(params.form.progressButtonClass);

                            // if (res.status &&
                            // typeof params.form.parent.find('.' + params.form.submitLinkClass).attr('data-analytic-evnt') !== undefined &&
                            // params.form.parent.find('.' + params.form.submitLinkClass).attr('data-analytic-evnt') !== false) {
                            // var evCat = params.form.parent.find('.' + params.form.submitLinkClass).attr('data-analytic-evnt');
                            // gtag("event", "event_name", {
                            //   "event_category": evCat,
                            //   "event_action": "send"
                            // });
                            // ym(27249305, 'reachGoal', evCat);
                            // }
                            // params.form.parent.find('input[name="g-recaptcha-response"]').remove();
                        },
                        error: function (err) {
                            console.log(err)
                            // params.form.parent.find('input[name="g-recaptcha-response"]').remove();
                        }
                    });
                };

                methods.eventFormSubmit();
            },
            'validateForm': function (e) {
                var returnState = methods.checkingFieldsAll(params.fields.initialNodes);

                // if fields valid then add progress state to form

                if (returnState == true) {
                    params.form.moduleParent.addClass(params.form.progressFormClass);
                    $('.' + params.form.submitLinkClass).addClass(params.form.progressButtonClass);
                }
                return returnState;
            },


            'eventChangeFields': function () {
                var $thisField = $(this);
                var thisFieldDataRaw = $.extend({}, $thisField.data()[params.fields.attr], {
                    thisField: $thisField,
                    thisValue: $.trim($thisField.val())
                });

                // checking this field
                if (params.inner.realTimeCheck) {
                    methods.checkingField($thisField);
                }

                // hidden checking all field for submit status
                methods.initFormStatus();

                // double fields logic, trigger secondary on primary
                if (thisFieldDataRaw.mask == "passwordPrimary") {
                    params.fields.passwordFields.secondaryNode.trigger('change');
                }
            },
            'checkingField': function ($thisField, hiddenChecking) {
                // hiddenChecking is check fields but don't change style

                var fieldStatus = true;

                var thisFieldDataRaw = $.extend({}, $thisField.data()[params.fields.attr], {
                    thisField: $thisField,
                    thisValue: $.trim($thisField.val())
                });

                var thisFieldData = $.extend({}, params.inner.defaultFieldData, thisFieldDataRaw);

                // checking empty value

                if ($thisField.val().length === 0) {
                    $thisField.closest('.' + params.fields.parentClass).removeClass(params.fields.formHasValueClass);
                } else {
                    $thisField.closest('.' + params.fields.parentClass).addClass(params.fields.formHasValueClass);
                }

                // checking for mask
                if (methods.checkingMasks[thisFieldData.mask]) {
                    if (methods.checkingMasks[thisFieldData.mask](thisFieldData) || methods.checkingMasks['visibleAndRequire'](thisFieldData)) {
                        // remove error class
                        if (!hiddenChecking) {
                            $thisField.closest('.' + params.fields.parentClass).removeClass(params.fields.parentErrorState);
                        }
                    } else {
                        // added error class
                        if (!hiddenChecking) {
                            $thisField.closest('.' + params.fields.parentClass).addClass(params.fields.parentErrorState);
                        }

                        // added error status
                        fieldStatus = false;
                    }
                }

                return fieldStatus;
            },
            'checkingFieldsAll': function ($fields, hiddenChecking) {
                var checkingParams = {
                    'status': true,
                    'focusFlag': true,
                    'scrollNode': $('body,html'),
                    'scrollSpeed': 300,
                    'scrollDelay': 100,
                    'scrollOffsetShift': 300
                };
                $fields.each(function (i, thisField) {
                    var $thisField = $(thisField);
                    var thisFieldStatus = methods.checkingField($thisField, hiddenChecking);
                    if (!thisFieldStatus) {
                        checkingParams.status = false;

                        // scroll to error field and focus
                        if (checkingParams.focusFlag && !hiddenChecking && !$thisField.is(':checkbox')) {
                            checkingParams.focusFlag = false;
                            $thisField.focus();
                        }
                    }
                });
                return checkingParams.status;
            },
            'checkingMasks': {
                'text': function (data) {
                    return (data.thisValue.length > 0);
                },
                'text1000': function (data) {
                    return (data.thisValue.length < 1001);
                },
                'text1000require': function (data) {
                    return (data.thisValue.length < 1001 && data.thisValue.length > 0);
                },
                'length4': function (data) {
                    if (data.thisValue.length > 4) {
                        $(data.thisField).val(data.thisValue.slice(0, 4));
                    }
                    return (data.thisValue.length == 4);
                },
                'length6': function (data) {
                    if (data.thisValue.length > 6) {
                        $(data.thisField).val(data.thisValue.slice(0, 6));
                    }
                    return (data.thisValue.length == 6);
                },
                'length10': function (data) {
                    return (data.thisValue.length > 9);
                },
                'phone': function (data) {
                    return (data.thisValue.length === 18);
                },
                'textInn': function (data) {
                    return (data.thisValue.length === 12);
                },
                'textBik': function (data) {
                    return (data.thisValue.length === 9);
                },
                'email': function (data) {
                    return (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(data.thisValue));
                },
                'phone&email': function (data) {
                    var res = false;
                    var phone = (/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(data.thisValue));
                    if (!phone) {
                        var email = (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(data.thisValue));
                    }
                    if (phone == true || email == true) res = true;
                    return res;
                },
                'select': function (data) {
                    return !(data.thisValue == 0);
                },
                'checkbox': function (data) {
                    return (data.thisField.is(':checked'));
                },
                'customRadio': function (data) {
                    return (data.thisValue.length > 0 && data.thisValue != 0);
                },
                'visibleAndRequire': function (data) {
                    // invisible field
                    if (data.visible && !data.thisField.closest('.' + params.fields.parentClass).is(':visible')) {
                        return true;
                    }

                    // not require field
                    if (!data.require && data.thisValue.length > 0) {
                        return true;
                    }

                    // return default
                    return false;
                },
                'passwordPrimary': function (data) {
                    return (data.thisValue.length > 5);
                },
                'passwordSecondary': function (data) {
                    return (data.thisValue.length > 5 && (params.fields.passwordFields.primaryNode.val() === params.fields.passwordFields.secondaryNode.val()));
                },
                'selectChosen': function (data) {
                    return (data.thisValue.length > 0);
                },
                'postCode': function (data) {
                    return (/^\d+$/.test(data.thisValue) && data.thisValue.length === 6);
                },
                'groupRadio': function (data) {
                    return data.thisField.closest('.field-group').find('input:checked').length;
                },
                'groupCheckbox': function (data) {
                    return data.thisField.closest('.field-group').find('input:checked').length;
                }
            },
            'eventFocusEnter': function () {
                $(this).closest('.' + params.fields.parentClass).addClass(params.fields.formFocusClass);
            },
            'eventFocusLeave': function () {
                $(this).closest('.' + params.fields.parentClass).removeClass(params.fields.formFocusClass)
            }
        };

        methods.init();

    },
    'handle-file-upload': function ($thisModule) {
        if (window.File && window.FileList && window.FileReader) {
            var $parent = $thisModule.closest('.is-form-field');
            var $placeholder = $parent.find('.input-placeholder');
            var $clearBtn = $parent.find('.js_clear-field');
            var $inptBtn = $parent.find('.js_input-trigger');
            $thisModule.on('change', function (e) {
                var files = e.target.files,
                    filesLength = files.length;
                if (filesLength) $parent.addClass('is-attached');
                for (var i = 0; i < filesLength; i++) {
                    var f = files[i];
                    var fileReader = new FileReader();
                    $placeholder.text(f.name);
                    // fileReader.readAsDataURL(f);
                }
            });
            $inptBtn.on('click', function () {
                $thisModule.trigger('click');
            })
            $clearBtn.on('click', function () {
                $thisModule.val('');
                $parent.removeClass('is-attached');
                $placeholder.text('Файл не выбран');
            })
        }
    },
    'qty-box': function ($thisModule) {
        var $btnMinus, $btnPlus, $input, currentVal;
        $btnPlus = $thisModule.find('.js_btn-plus');
        $btnMinus = $thisModule.find('.js_btn-minus');
        $input = $thisModule.find('input');
        currentVal = $input.val();

        $input.inputFilter(function (value) {
            return /^\d*$/.test(value);
        });

        if (parseFloat($input.val()) < 2) $btnMinus.addClass('disabled');

        $input.on('change', function () {
            if (this.value.charAt(0) == '0' || this.value == '') {
                this.value = currentVal;
            } else {
                currentVal = this.value;
            }
            if (parseFloat($input.val()) < 2) {
                $btnMinus.addClass('disabled');
            } else {
                $btnMinus.removeClass('disabled');
            }
        });

        $btnPlus.on('click', function () {
            var oldValue = $input.val();
            var newVal = parseFloat(oldValue) + 1;
            $input.val(newVal);
            $input.trigger('change');
        });

        $btnMinus.on('click', function () {
            if (parseFloat($input.val()) < 2) return false;
            var oldValue = $input.val();
            var newVal = parseFloat(oldValue) - 1;
            $input.val(newVal);
            $input.trigger('change');
        });
    },
    'numeric-input': function ($thisModule) {
        var $input = $thisModule,
            currentVal = $input.val();
        $input.inputFilter(function (value) {
            return /^\d*$/.test(value);
        });
        $input.on('change', function () {
            if (this.value.length > 1 && this.value.charAt(0) == '0' || this.value == '') {
                this.value = currentVal;
            } else {
                currentVal = this.value;
            }
        });
    },
    'datepicker': function ($thisModule) {
        var start = new Date();
        var datePicker = $thisModule.datepicker({
            dateFormat: 'dd.mm.yyyy',
            language: 'ru',
            minDate: start,
            keyboardNav: false,
            onSelect: function (formattedDate, date, calendar) {
                calendar.hide();
            }
        }).data('datepicker');
    },
    'simple-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        $pager = $thisModule.find('.js_pager');
        var $slider = new Swiper($parent, {
            slidesPerView: 1,
            spaceBetween: 24,
            loop: true,
            preloadImages: false,
            autoHeight: false,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            pagination: {
                el: $pager,
                clickable: true,
            },
            breakpoints: {
                767: {
                    autoHeight: true,
                },
            },
        });
    },
    'product-gallery': function ($thisModule) {
        var $big = $thisModule.find('.page-product__gallery__big .swiper-container'),
            $thumbs = $thisModule.find('.page-product__gallery__thumbs .swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next'),
            $pager = $thisModule.find('.js_pager');
        var thumbs = new Swiper($thumbs, {
            spaceBetween: 10,
            slidesPerView: 6,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
        });
        var slider = new Swiper($big, {
            slidesPerView: 1,
            spaceBetween: 24,
            loop: true,
            preloadImages: false,
            autoHeight: false,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            pagination: {
                el: $pager,
                clickable: true,
            },
            thumbs: {
                swiper: thumbs
            },
            breakpoints: {
                767: {
                    autoHeight: true,
                },
            },
        });
    },
    'news-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 24,
            loop: false,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
            },
        });
    },
    'anchor-menu': function($thisModule){
      var link = $thisModule.find('.js-am-trigger');
      link.click(function () {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              if (target.length) {
                  $('html, body').animate({
                      scrollTop: target.offset().top - 50
                  }, 500);
                  return false;
              }
          }
      });
    },
    'gifts-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: '5',
            slidesPerGroup: 5,
            spaceBetween: 24,
            loop: false,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 5,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 16,
                }
            }
        });
    },
    'partners-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: 'auto',
            loop: true,
            centeredSlides: true,
            centeredSlidesBounds: true,
            preloadImages: false,
            roundLengths: true,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 5,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
        });
    },
    'letters-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: 6,
            spaceBetween: 24,
            loop: true,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 5,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                }
            }
        });
    },
    'services-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: 'auto',
            spaceBetween: 48,
            centeredSlides: true,
            preloadImages: false,
            roundLengths: true,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 5,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    spaceBetween: 16,
                }
            }
        });
    },
    'services-other-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: 3,
            spaceBetween: 24,
            preloadImages: false,
            roundLengths: true,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 5,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 'auto',
                    spaceBetween: 16,
                    centeredSlides: true,
                }
            }
        });
    },
    'products-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 24,
            loop: false,
            preloadImages: false,
            loadOnTransitionStart: true,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 5,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            on: {
                lazyImageReady: function (slideEl, imageEl) {
                    var $parent = $(slideEl).find('.js_prod-parent');
                    $parent.each(function (idx, el) {
                        var h = $(el).outerHeight();
                        $(el).find('.js_hover-target').css({ "padding-top": (h + 8) + "px" });
                    })
                }
            },
            breakpoints: {
                767: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 16,
                }
            }
        });
    },    
    'articles-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 28,
            loop: false,
            preloadImages: false,
            loadOnTransitionStart: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 16,
                }
            }
        });
    },
    'reviews-slider': function ($thisModule) {
        var $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        var $slider = new Swiper($parent, {
            spaceBetween: 80,
            loop: true,
            autoHeight: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            }
        });
    },
    'pagination': function ($thisModule) {
        var pages = $thisModule.data('total-pages');
        var url = $thisModule.data('url');
        $thisModule.pagination({
            dataSource: function (done) {
                var result = [];
                for (var i = 1; i <= pages; i++) {
                    result.push(i);
                }
                done(result);
            },
            pageRange: 1,
            pageSize: 1
        });
        $thisModule.addHook('beforePaging', function (page) {
        });
    },
    'contacts-map': function ($thisModule) {
        var requestAPI = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';

        scriptLoading(requestAPI, function () {
            ymaps.ready(init);
        })

        function init() {
            ymaps.ready(function () {
                var myMap = new ymaps.Map('map', {
                    center: [55.751574, 37.573856],
                    zoom: 16,
                    controls: ['zoomControl'],
                    behaviors: ['default', 'scrollZoom']
                }),

                    getPointData = [
                        { balloonContentBody: "<div class='ballon-point'>ул. Большая Никитская, д. 37, стр. 1, Москва, Россия" },
                    ],

                    // getPointOptions = function () {
                    //   return {
                    //     iconLayout: 'default#image',
                    //     iconImageHref: '/img/pin.svg',
                    //     iconImageSize: [24, 27],
                    //     iconImageOffset: [-12, -27]
                    //   };
                    // },

                    points = [
                        [55.75711606898019, 37.59364149999996]
                    ],
                    geoObjects = [];

                for (var i = 0, len = points.length; i < len; i++) {
                    geoObjects[i] = new ymaps.Placemark(points[i], getPointData[i]/*, getPointOptions()*/);
                    myMap.geoObjects.add(geoObjects[i]);
                    geoObjects[i].events.add('click', function (e) {

                    })
                }

                myMap.setCenter(points[0], 16, {
                    checkZoomRange: true
                });
                myMap.behaviors.disable('scrollZoom');
            });
        }

        function scriptLoading(src, callback) {
            var script = document.createElement('script'),
                loaded;
            script.setAttribute('src', src);
            if (callback) {
                script.onreadystatechange = script.onload = function () {
                    if (!loaded) {
                        callback();
                    }
                    loaded = true;
                };
            }
            document.getElementsByTagName('head')[0].appendChild(script);
        };
    },
    'rating-module': function ($thisModule) {
        $('#js-rating').rating({
            fx: 'float',
            image: '/img/stars.png',
            loader: '/img/ajax-loader.gif',
            minimal: 0,
            url: '/ajax/rating.php',
            callback: function(response){
            }
        });
    },
    toTop: function () {
      var $body = $('html, body');
      var $window = $(window);
      var $btn = $('.js-to-top');
      $window.on('scroll', function () {
        if ($window.scrollTop() > 100) {
          $btn.addClass('showed');
        } else {
          $btn.removeClass('showed');
        }
      });
      $btn.on('click', function (e) {
        e.preventDefault();
        $body.animate({'scrollTop': 0}, 500);
      });
    }
};

var pageApp = {
    'init': function () {
        var curApp = $('#app').attr('data-app');
        if (pageApp[curApp]) {
            pageApp[curApp]();
        }
    },
    'page-index': function () {
        var $elemsList = $('#index-elems-list');
        var $gridCell = $elemsList.find('.is-grid__item');

        // $gridCell.each(function(idx, el) {
        //     var h = $(el).outerHeight();
        //     $(el).find('.product-snippet__hover').css({"padding-top": (h + 8) + "px"});
        // })

        function jsCall() {
            setTimeout(function () {
                $elemsList.removeClass('preload before-preload-leave');
            }, 701);
            $elemsList.addClass('before-preload-leave');
        }

        $window.on('load', jsCall())
    },
    'page-catalog': function () {
        var $elemsList = $('#catalog-elems-list');
        var $gridCell = $elemsList.find('.is-grid__item');

        // $gridCell.each(function(idx, el) {
        //     var h = $(el).outerHeight();
        //     $(el).find('.product-snippet__hover').css({"padding-top": (h + 8) + "px"});
        // })

        function jsCall() {
            setTimeout(function () {
                $elemsList.removeClass('preload before-preload-leave');
            }, 701);
            $elemsList.addClass('before-preload-leave');
        }

        $window.on('load', jsCall())
    }
};

var analytics = {
    init: function () {
        $('[data-analytics]').on('click', function (e) {
            var $this = $(e.currentTarget);

            if (analytics[$this.data('analytics')]) {
                analytics[$this.data('analytics')]($this.data('value'));
            }
        });
    },
    checkY: function () {
        return typeof ym !== 'undefined';
    },
    checkG: function () {
        return typeof gtag !== 'undefined';
    },
    social: function (name) {
        analytics.checkG() && gtag("event", "event_name", { "event_category": "social", "event_action": "perehod", "event_label": name });
        analytics.checkY() && ym(57410872, 'reachGoal', 'social');
    },
    registration: function () {
        analytics.checkG() && gtag("event", "event_name", { "event_category": "check_in", "event_action": "send" });
        analytics.checkY() && ym(57410872, 'reachGoal', 'check_in');
    },
    login: function () {
        analytics.checkG() && gtag("event", "event_name", { "event_category": "vxod_kabinet", "event_action": "send" });
        analytics.checkY() && ym(57410872, 'reachGoal', 'vxod_kabinet');
    },
    filter: function (xxx, yyy) {
        analytics.checkG() && gtag("event", "event_name", { "event_category": "filter", "event_action": xxx, "event_label": yyy });
        analytics.checkY() && ym(57410872, 'reachGoal', 'filter');
    },
    callback: function () {
        analytics.checkG() && gtag("event", "event_name", { "event_category": "callback", "event_action": "send" });
        analytics.checkY() && ym(57410872, 'reachGoal', 'callback');
    },
    review: function () {
        analytics.checkG() && gtag("event", "event_name", { "event_category": "feedback", "event_action": "send" });
        analytics.checkY() && ym(57410872, 'reachGoal', 'feedback');
    },
    addToCart: function (items) {
        // gtag('event', 'add_to_cart', {
        //     "items": [
        //         {
        //             "id": "P12345",
        //             "name": "Android Warhol T-Shirt",
        //             "brand": "Google",
        //             "quantity": 2,
        //             "price": '2.0',
        //             "currency": "USD"
        //         }
        //     ]
        // });
        analytics.checkG() && gtag('event', 'add_to_cart', {
            "items": items
        });

        analytics.checkY() && ym(57410872, 'reachGoal', 'Btn_add_to_cart');
    },
    removeFromCart: function (items) {
        // gtag('event', 'remove_from_cart', {
        //     "items": [
        //         {
        //             "id": "P12345",
        //             "name": "Android Warhol T-Shirt",
        //             "brand": "Google",
        //             "quantity": 2,
        //             "price": '2.0',
        //             "currency": "USD"

        //         }
        //     ]
        // });
        analytics.checkG() && gtag('event', 'remove_from_cart', {
            "items": items
        });
        analytics.checkY() && ym(57410872, 'reachGoal', 'remove_from_cart');
    },
    checkout: function (items) {
        // gtag('event', 'begin_checkout', {
        //     "items": [
        //         {
        //             "id": "P12345",
        //             "name": "Android Warhol T-Shirt",
        //             "brand": "Google",
        //             "quantity": 2,
        //             "price": '2.0',
        //             "currency": "USD"

        //         }
        //     ],
        // });
        analytics.checkG() && gtag('event', 'begin_checkout', {
            "items": items
        });

        analytics.checkY() && ym(57410872, 'reachGoal', 'begin_checkout');
    },
    purchase: function (id, value, currency, items) {
        // gtag('event', 'purchase', {
        //     "transaction_id": "24.031608523954162",
        //     "value": 23.07,
        //     "currency": "USD",
        //     "items": [
        //         {
        //             "id": "P12345",
        //             "name": "Android Warhol T-Shirt",
        //             "brand": "Google",
        //             "quantity": 2,
        //             "price": '2.0',
        //             "currency": "USD"

        //         },
        //         {
        //             "id": "P67890",
        //             "name": "Flame challenge TShirt",
        //             "brand": "MyBrand",
        //             "quantity": 1,
        //             "price": '3.0',
        //             "currency": "USD"

        //         }
        //     ]
        // });
        analytics.checkG() && gtag('event', 'purchase', {
            "transaction_id": '' + id,
            "value": value,
            "currency": currency,
            "items": items
        });

        analytics.checkY() && ym(57410872, 'reachGoal', 'purchase');
    },
}

$(document).ready(function () {
    moduleApp.init();
    pageApp.init();
    analytics.init();
});
